* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.z {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  border: none;
  background: linear-gradient(90deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(0deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px), #269;
  background: linear-gradient(0deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(90deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px), #269;
  background: linear-gradient(0deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(90deg, #ccc 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px),
    transparent;
  background-position: -1px -1px, -1px -1px, -1px -1px, -1px -1px;
  background-origin: padding-box;
  background-clip: border-box;
}

.z-200 {
  background-size: 64px 64px, 64px 64px, 32px 32px, 32px 32px;
  z-index: 1;
  position: absolute;
  top: 0;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #e9e6e3;
  background: radial-gradient(circle, #82807f 0%, #524e4c 100%);
  background: #524e4c;
}
