/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;

  /* IE9 Compat Modes */
  src: url('./fonts/noto-sans-v27-latin-regular.eot');

  /* Legacy iOS */
  src: local(''),
    url('./fonts/noto-sans-v27-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/noto-sans-v27-latin-regular.woff2') format('woff2'),
    url('./fonts/noto-sans-v27-latin-regular.woff') format('woff'),
    url('./fonts/noto-sans-v27-latin-regular.ttf') format('truetype'),
    url('./fonts/noto-sans-v27-latin-regular.svg#NotoSans') format('svg');
}

/* noto-sans-600 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;

  /* IE9 Compat Modes */
  src: url('./fonts/noto-sans-v27-latin-600.eot');

  /* Legacy iOS */
  src: local(''),
    url('./fonts/noto-sans-v27-latin-600.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/noto-sans-v27-latin-600.woff2') format('woff2'),
    url('./fonts/noto-sans-v27-latin-600.woff') format('woff'),
    url('./fonts/noto-sans-v27-latin-600.ttf') format('truetype'),
    url('./fonts/noto-sans-v27-latin-600.svg#NotoSans') format('svg');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;

  /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v24-latin-regular.eot');

  /* Legacy iOS */
  src: local(''),
    url('./fonts/montserrat-v24-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/montserrat-v24-latin-regular.woff2') format('woff2'),
    url('./fonts/montserrat-v24-latin-regular.woff') format('woff'),
    url('./fonts/montserrat-v24-latin-regular.ttf') format('truetype'),
    url('./fonts/montserrat-v24-latin-regular.svg#Montserrat') format('svg');
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;

  /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v24-latin-600.eot');

  /* Legacy iOS */
  src: local(''),
    url('./fonts/montserrat-v24-latin-600.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/montserrat-v24-latin-600.woff2') format('woff2'),
    url('./fonts/montserrat-v24-latin-600.woff') format('woff'),
    url('./fonts/montserrat-v24-latin-600.ttf') format('truetype'),
    url('./fonts/montserrat-v24-latin-600.svg#Montserrat') format('svg');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#root {
  overflow-y: auto;
}

@media (hover: hover) {
  button.product-guide-button:hover {
    background-color: white;
  }
}

/* Imported styles for dropdown component from ECHO library */

.echo-menu {
  border-color: #706e6b;
  border-width: 1px;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-sizing: border-box;
  border-color: rgb(224 220 216 / var(--tw-border-opacity));
  z-index: 10;
}

.echo-menu__item {
  --tw-text-opacity: 1;
  border-bottom: 1px solid #d2cdc8;
  color: rgb(42 40 38 / var(--tw-text-opacity));
  cursor: pointer;
  font: var(--echo-text-style-body-l);
  line-height: 1rem;
  padding: 1rem;
  text-align: left;
  width: 100%;
}

.echo-menu__item:last-child {
  border-bottom: unset;
}

.echo-menu__item:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(239 237 236 / var(--tw-bg-opacity));
}
